/**
 * Route component that also renders subroutes
 * Configuration found in: /config/routing.js
 */
import React from 'react';
import {Route} from 'react-router-dom';

export default class RouteWithSubRoutes extends React.Component {
    render() {
        return (<Route
                path={this.props.path}
                render={props => (
                    // pass the sub-routes down to keep nesting
                    <this.props.component {...this.props} routes={this.props.routes} />
                )}
            />
        );
    }
}