const fr = {
    translation: {
        common: {
            notFound: {
                title: "404 - pagina niet gevonden",
                content: "De pagina <strong>{{ url }}</strong> te bezoeken kan niet gevonden worden"
            },
            loading: "Laden...",
            requiredField: "Dit veld is verplicht",
            invalidMail: "Dit is geen geldig e-mailadres",
            send: "Verzenden",
            topbarTitle: "Terug naar J&T Autolease",
            noCarsFound: "Er zijn geen auto's gevonden binnen jouw zoekopdracht.",
            mainTitle: "EV Keuzehulp Frans"
        },
        calculationSummary: {
            title: "Op basis van de berekening is je gemiddelde actieradius per dag",
            radiusInfo: "Overzicht verfijnen op actieradius",
            back: "Terug",
            newCalculation: "Nieuwe berekening maken"
        },
        calculationForm: {
            kilometers: {
                title: "Kilometers",
                content: "Wij gaan er in onze berekening vanuit dat de auto per dag over 1 volle lading beschikt.",
                privateKmLabel: "Hoeveel KM rijd je jaarlijks privé",
                houseWorkKmLabel: "Hoeveel KM woon-werk rijd je per dag",
                houseWorkKmLabelExtra: "(Enkele rit)"
            },
            work_week: {
                title: "Werkweek",
                content: "Op basis van je werkweek, woon-werk verkeer en je jaarlijkse privé kilometers berekenen wij je gemiddelde verste rit. In de volgende stap kan je het resultaat verfijnen op basis van het aantal kilometers per dag.",
                label: "Ik werk",
                oneDay: "1 dag per week",
                twoDays: "2 dagen per week",
                threeDays: "3 dagen per week",
                fourDays: "4 dagen per week",
                fiveDays: "5 dagen per week",
                workFromHomeLabel: "Waarvan thuiswerkdagen",
                workFromHomeNoDays: "Geen thuiswerkdagen",
                workFromHomeOneDay: "1 thuiswerkdag",
                workFromHomeTwoDays: "2 thuiswerkdagen",
                workFromHomeThreeDays: "3 thuiswerkdagen",
                workFromHomeFourDays: "4 thuiswerkdagen",
                workFromHomeFiveDays: "5 thuiswerkdagen",
                externalLocationsLabel: "Externe locaties bezoek ik",
                notApplicable: "Niet van toepassing",
                weeklyVisits: "Wekelijkse bezoeken",
                averageSearchArea: "Selecteer je gemiddelde bezoekgebied"

            },
            income: {
                title: "Inkomen",
                content: "Wil je de auto privé rijden en rijd je meer dan 500 kilometer per jaar privé, dan dien je bijtelling te betalen. De fiscale waarde van de auto, het bijtellingspercentage en de hoogte van de belastingschijf, toepasbaar op jouw salaris, hebben invloed op het bijtellingsbedrag. Geef daarom hieronder aan in welke belastingschijf jij valt op basis van je jaarinkomen.",
                contentSecond: "In onze berekening worden de gevolgen van heffingskortingen niet meegerekend. Het resultaat op de volgende pagina betreft altijd het fiscale bijtellingsbedrag.",
                label: "Optioneel",
                subTitle: "Inkomen & bijtelling",
                incomeLabel: "Wat is jouw inkomen?",
                maxPayment: "Hoeveel bijtelling wil je maximaal per maand netto betalen?"
            },
            default: {
                title: "Informatie"
            },
            introContent: "Hulp bij jouw keuze voor een elektrische auto. Overweeg je een zakelijke elektrische leaseauto? Wij helpen je met het maken van de juiste keuze. Beantwoord hieronder enkele vragen en vind de elektrische auto die het beste bij jou past.",
            buttonLabel: "Start berekening"
        },
        carResults: {
            chargingTime: "Laadtijdsnellader",
            actionRadiusCity: "Actieradius stad",
            actionRadiusHighway: "Actieradius snelweg",
            actionRadius: "Actieradius",
            fiscalValue: "Fiscale waarde",
            addition: "Bijtelling",
            range: "Hoe ver kom ik?",
            mailResults: "Mail mij deze keuze",
            filter: {
                allBrands: "Alle merken",
                filterByBrand: "Filteren op merk"
            }
        },
        footer: {
            privacyStatement: "Privacy verklaring",
            disclaimer: "Disclaimer",
            termsAndConditions: "Algemene voorwaarden",
            cookies: "Cookies"
        },
        mailCarModel: {
            errorSendingMail: "Er ging iets mis met het verzenden van de mail. Probeer het later opnieuw",
            title: "Mail mij deze keuze",
            subTitle: "Vul je gegevens in",
            choiceSend: "We hebben jouw keuze gemaild.",
            firstName: "Voornaam",
            lastName: "Achternaam",
            email: "E-mailadres",
            number: "Telefoonnummer",
            companyName: "Bedrijfsnaam",
            remarks: "Eventuele opmerkingen"
        },
        radiusModal: {
            singleRide: "Enkele rit",
            retour: "Retour",
            loading: "Bezig met ophalen van locatie...",
            modalTitle: "Hoe ver kom ik?"
        }
    }
}

export default fr
