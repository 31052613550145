import React, {Suspense} from 'react';

import {Switch, Route, useLocation} from 'react-router-dom';
import routes from './config/routing';
import RouteWithSubRoutes from './components/RouteWithSubRoutes';
import NotFound from "./containers/NotFound"
import {connect} from "react-redux"


// Import stylesheet
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/body.scss';
import './styles/page-transitions.scss';

// Load components
import LoadingIndicator from "./components/LoadingIndicator";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";

// Load Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-19882801-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App( {dispatch, user, report} ) {
    // Fetch current location used for animations
    let location = useLocation();

    return (
        <div className="global-container">
            <TopBar />
            <Suspense fallback={<LoadingIndicator />}>
                <Switch location={location}>
                    {routes.map((route, i) => (
                        <RouteWithSubRoutes key={`route-${i}`} {...route} />
                    ))}
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Suspense>
            <Footer />
        </div>
    );
}

/* Connect Store state to local props */
const mapStateToProps = state => ({
    user: state.user,
})
export default connect(mapStateToProps)(App);
