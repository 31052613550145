/**
 * Import translations here
 */

import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import nl from "./nl";
import fr from "./fr";
import be from "./be";
let currentLanguage = 'nl'

if (process.env.REACT_APP_MODE === 'be') {
    currentLanguage = 'be'

    if (sessionStorage.getItem('selectedLanguage')) {
        currentLanguage = JSON.parse(sessionStorage.getItem('selectedLanguage'));
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources: {
            nl: nl,
            be: be,
            fr: fr
        },
        lng: currentLanguage,
        fallbackLng: "be",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;