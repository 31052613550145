/**
 * User actions
 */
import {SET_USER, UNSET_USER} from "../actionTypes";

const user = (state =  null, action) => {
    switch (action.type) {
        case SET_USER: {
            // also store user into local storage
            localStorage.setItem("user", JSON.stringify(action.payload.state));
            return action.payload.state;
        }
        case UNSET_USER: {
            localStorage.removeItem("user");
           return null;
        }
        default: {
            return state;
        }
    }
};

export default user;
