import React from 'react';
import ReactDOM from 'react-dom';
import {withTranslation} from "react-i18next";
import "./stylesheet.scss";
import logo from '../../assets/logo.png';
import beFlag from '../../assets/BE.svg';
import frFlag from '../../assets/FR.svg';
import {BsArrowLeft} from "react-icons/all";

class TopBar extends React.Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            showDropdown: false,
            i18n: this.props.i18n,
            languages: this.getLanguages()
        }
    }

    getLanguages(){
        const lang = [
            {
                abbreviation: 'be',
                nativeName: 'Belgium',
                shortName: 'BE',
                active: this.props.i18n.language.toUpperCase() === 'BE' ? 1 : 0
            },
            {
                abbreviation: 'fr',
                nativeName: 'French',
                shortName: 'FR',
                active: this.props.i18n.language.toUpperCase() === 'FR' ? 1 : 0
            }
        ];
        lang.sort(function (a, b) {
            return a.active - b.active;
        });
        return lang;
    }

    /**
     * Adds the handleClickOutside handler on component mount
     */
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    /**
     * If anything but the topbar is clicked, the language dropdown will close
     * @param event
     */
    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                showDropdown: false
            });
        }
    }

    /**
     * Changes the language to the clicked language
     * Closes dropdown
     * Stores selected language in sessionStorage
     * @param clickedLang
     */
    handleFlagClick(clickedLang) {
        const {i18n} = this.props;
        i18n.changeLanguage(clickedLang);
        this.setState({
            languages: this.getLanguages(),
            showDropdown: false
        });
        sessionStorage.setItem('selectedLanguage', JSON.stringify(i18n.language));
    }

    render() {
        const {i18n} = this.props;

        return (
            <div className="top-bar">
                <a href="https://www.jentautolease.nl/" className="top-bar__logo">
                    <img src={logo} alt="jent autolease"/>
                </a>

                <a href="https://www.jentautolease.nl" className="top-bar__anchor">
                    <BsArrowLeft className="icon"/> &nbsp;
                    {this.props.t('common.topbarTitle')}
                </a>
                {process.env.REACT_APP_MODE === 'be' &&
                    <>
                        <div className="language-switcher">
                            <div className="language-switcher__current"
                                 onClick={() => this.setState({showDropdown: !this.state.showDropdown})}>
                                <img className="flag"
                                     src={(i18n.language.toUpperCase() === 'FR') ? frFlag : beFlag}
                                     alt="Selected language flag"/>
                            </div>
                            {this.state.showDropdown &&
                                <>
                                    <div className="language-switcher__dropdown">
                                        {
                                            this.state.languages.map((lang) => {
                                                return (
                                                    <div
                                                        className="dropdown-flag"
                                                        onClick={() => this.handleFlagClick(lang.abbreviation)}
                                                        key={lang.abbreviation}>
                                                        <img
                                                            alt={lang.shortName}
                                                            src={(lang.shortName === 'FR') ? frFlag : beFlag}
                                                            className="flag"/>
                                                        <p>{lang.nativeName}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default withTranslation()(TopBar);