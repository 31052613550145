import React from 'react';
import {connect} from 'react-redux';

import {withTranslation} from "react-i18next";

class NotFound extends React.Component {

    createMarkup() {
        return {__html: 'First &middot; Second'};
    }


    render() {

        const {t} = this.props;
        const data = t('common.notFound.content', {url: window.location.pathname.replace(/\//g,'')})

        return (<div className="page-not-found">
            <h1>{t('common.notFound.title')}</h1>
            <p dangerouslySetInnerHTML={{__html: data}}></p>
        </div>);
    }
}

export default withTranslation()(connect()(NotFound));
// export default NotFound