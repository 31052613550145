import { lazy } from 'react';

const Home = lazy(() => import('../containers/Home'));

const routes = [
    {
        exact: true,
        path: "/",
        component: Home,
        name: 'EV-Tool',
        menu: true
    },
];
export default routes;
